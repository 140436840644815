.example {
  display: flex;
  flex-direction: column;
  min-height: 40px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  flex-basis: 100px;
  border: 1px solid silver;
}

.example--sign {
  flex-basis: 60px;
}

.example--result {
  flex: 1;
  align-items: flex-start;
}

.example--subnumber {
  width: 100%;
  text-align: right;
  padding: 5px;
}

.example--subnumber-first {
}
