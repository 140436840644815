.final {
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background: white;
  position: relative;
  border: 1px solid silver;
}

.final--empty {
  width: 100%;
  height: 60px;
}

@media print {
  .final {
    border: 0;
  }
}
