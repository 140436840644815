.printExample {
  display: flex;
  flex-direction: column;
  min-height: 40px;
  align-items: center;
  justify-content: center;
  flex-basis: 100px;
  border: 1px solid gray;
}

.printExample--sign {
  flex-basis: 60px;
}

.printExample--result {
  flex: 1;
  align-items: flex-start;
}

.printExample--subnumber {
  width: 100%;
  text-align: right;
  padding: 5px;
}

.printExample--subnumber-first {
}

@media print {
  .printExample-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .printExample-item-written-counting {
    width: 50%;
    margin-bottom: 90px;
    float: left;
  }

  .printExample {
    border: 0;
    flex-basis: 80px;
    height: 30px;
  }

  .printExample--sign {
    flex-basis: 20px;
  }

  .printExample--sign-equal {
    display: none;
  }

  .printExample--subnumber {
    width: 100%;
    text-align: right;
    padding: 0;
  }

  .printExample--subnumber-first {
    border-bottom: 0;
  }

  .printExample--subnumber-last {
    border-bottom: 1px solid gray;
    padding-bottom: 5px;
  }
}
