.result {
  display: flex;
  flex-direction: column;
  width: 110px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: 1px solid silver;
}

.result--fake {
  background: #4CAF50;
  border-color: #2F7D31;
  color: white;
}

@media print {
  .result {
    border: 0;
    width: 90px;
    height: 30px;
    align-items: flex-start;
  }
}
