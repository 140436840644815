@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page {
    margin: 10mm;
    display: block;
    page-break-before: always;
  }

  .page__header {
    margin-bottom: 5mm;
  }

  .page__header h3 {
    font-size: 14px;
  }

  .page__content{
    margin-top: 20px;
    font-size: .8rem !important;
  }

  .page__content table {
    width: 100%;
    border-collapse: collapse;
  }

  .page__content th {
    border: 1px solid silver;
    padding: 5px 10px;
    font-size: 10px;
  }

  .page__content td {
    border: 1px solid silver;
    padding: 5px 10px;
    font-size: 10px;
  }
}

@page {
  margin: 0;
}
